import { get, merge } from 'lodash';
// import { IMenuItem } from '../components/menu/menu-items.component';
import initConfiguration, { ConfItemType, ConfType } from '../config/index';
import { IConfiguration, MenuItem } from '../types';

export default class OverrideService {
  static modules: Array<string> = [];

  static menuItems: Array<MenuItem> = [];

  static configuration: IConfiguration = {
    config: initConfiguration,
    errorCodesBlacklist: [],
    errorMessages: {},
  };

  static storeConfiguration(configuration: any) {
    this.configuration = merge(this.configuration, configuration);
  }

  static getConfiguration() {
    return this.configuration ? this.configuration : null;
  }

  static getOverrides() {
    return this.configuration && this.configuration.overrides
      ? this.configuration.overrides
      : {};
  }

  static getConfig(): ConfItemType {
    return this.configuration && this.configuration.config
      ? this.configuration.config
      : {};
  }

  static setConfig(config: ConfItemType) {
    this.configuration.config = {
      ...this.configuration.config,
      ...config,
    };
  }

  static getRoutes() {
    return get(this.configuration, 'routes', []);
  }

  static getNavigation() {
    return get(this.configuration, 'navigation', {
      auth: {},
    });
  }

  static getStyles() {
    return this.configuration && this.configuration.styles
      ? this.configuration.styles
      : null;
  }

  static setStyles(styles: any) {
    this.configuration.styles = styles;
  }

  static getModules(): Array<string> {
    return this.modules;
  }

  static setModules(modules: Array<string>) {
    this.modules = [...modules];
  }

  static setMenuItems(menuItems: Array<any>) {
    this.menuItems = menuItems;
  }

  // static getMenuItems(): Array<IMenuItem> {
  static getMenuItems(): Array<MenuItem> {
    return this.menuItems;
  }

  static getErrorCodesBlacklist(): Array<string> {
    return this.configuration && this.configuration.errorCodesBlacklist
      ? this.configuration.errorCodesBlacklist
      : [];
  }

  static setErrorCodesBlacklist(errorCodesBlacklist: Array<string>) {
    this.configuration.errorCodesBlacklist = errorCodesBlacklist;
  }

  static getErrorMessage(messageName: string) {
    if (this.configuration && this.configuration.errorMessages && messageName) {
      return this.configuration.errorMessages[messageName];
    }
    return undefined;
  }
}
